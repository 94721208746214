import { template as template_a154654f109e454ea56ced9455506c35 } from "@ember/template-compiler";
const WelcomeHeader = template_a154654f109e454ea56ced9455506c35(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
