import { template as template_e9e940b1bc644dbe93510849e514ae50 } from "@ember/template-compiler";
const FKLabel = template_e9e940b1bc644dbe93510849e514ae50(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
