import { template as template_65e83e463c744459ac3aa2714897decb } from "@ember/template-compiler";
const FKControlMenuContainer = template_65e83e463c744459ac3aa2714897decb(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
