import { template as template_b890ce0df19c40d0bb5f107512cc56d2 } from "@ember/template-compiler";
const FKText = template_b890ce0df19c40d0bb5f107512cc56d2(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
